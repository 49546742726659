<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DropdownDefault from "@/components/dropdown-default";

import {
    categoryMethods,
    notificationMethods,
} from "@/state/helpers";

/**
 * Categories component
 */
export default {
  page: {
    title: "Gerenciar Categorias",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    DropdownDefault,
  },
  data() {
    return {
      tableData: [],
      title: "Gerenciar Categorias",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Categorias",
          active: true,
        },
      ],      
      loading: true,
      tableHighlighted: 0,
      rows: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        image: "Imagem",
        title: "Nome",
        actions: "Ações",
      },  
    }; 
  },
  computed: {    
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "5%" } },
        { key: "image", sortable: true, label: this.fieldAliases.image, thStyle: { width: "220px" } },
        { key: "title", sortable: true, label: this.fieldAliases.title },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  created() {
    this.clearNotifications();
    this.loadAll();
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    ...categoryMethods,
    ...notificationMethods,
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad() {
      this.$router.push('/category/create');
    },

    async loadAll() {
      this.loading = true;
      let registros = [];      
      registros = await this.getAllCategory();
      this.tableData = registros?.data || [];
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    getImage(image) {
      let imageUrl = image;
      imageUrl = imageUrl.replace(/\\/g, '');
      return imageUrl;
    },

    edit(category) {
      this.$router.push(`category/edit/${category.id}`);
    },
    
    async exclude(obj) {      
      await this.deleteCategory(obj.id);
      this.loadAll();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Nova Categoria'" @newCad="handleNewCad" />
    <b-alert 
      v-if="notification.message" 
      show 
      variant="warning" 
      dismissible      
      @dismissed="clearNotifications()"
      >
      {{ notification.message }}
    </b-alert>
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Categorias</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>
              <b-table              
                v-if="!loading"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- Coluna "image" personalizada -->
                <template #cell(image)="category">
                  <div class="actions-cell">   
                    <b-img
                      :src="getImage(category.item.image)"                      
                      :name="category.item.imagem"
                      width="140"
                      height="140"                      
                      alt="140x140"
                    ></b-img>
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">
                    <DropdownDefault @edit="edit(category.item)" @exclude="exclude(category.item)"></DropdownDefault>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </Layout>
</template>
